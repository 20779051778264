$(document).ready(function () {
    // sly
    $("#cycleitems").sly();

    jumpToCore();
    jumpToFundaments();
    jumpToInteractions();
    jumpToTop();
});

function jumpToCore() {
    $("#_core_business_menu").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_core_business").offset().top,
            },
            200
        );
    });
}

function jumpToFundaments() {
    $("#_fundaments_menu").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_fundaments").offset().top,
            },
            200
        );
    });
}
function jumpToInteractions() {
    $("#_interactions_menu").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_interactions").offset().top,
            },
            200
        );
    });
}

function jumpToTop() {
    $(".jump-top").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_menu_second").offset().top,
            },
            200
        );
    });
}
