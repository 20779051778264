/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';

//My js files

//My css files
// import './css/main.scss';
// import './css/main.css';
// import "./css/slippry.css";
import './css/style.css';
import './css/responsiveslides.css';
import './css/normalize.css';
import './css/business.css';
import './css/horizontal.css';
import './css/about.css';
import './css/dimbox.min.css';
import './css/jBox.all.min.css';

import './js/main';
import './js/jquery.animateTyping';
import 'preline/dist/preline';
import './js/responsiveslides.min';
import scrollSpy from 'simple-scrollspy';
import './js/sly.min';
import './js/horizontal';
import './js/plugins';
import './js/myscript';
import './js/business';
import './js/about';
import './js/dimbox.min';
import './js/jBox.all.min';
import './js/jquery.matchHeight-min';

window.scrollSpy = scrollSpy;

// start the Stimulus application
// import './bootstrap';
// import 'tw-elements';
// import 'tailwindcss';
