// import "../css/style.css";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
AOS.init();
// import "../../../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
// import "../../../node_modules/@fortawesome/fontawesome-free/css/regular.css";
// import "../../../node_modules/@fortawesome/fontawesome-free/css/solid.css";
// import "../../../node_modules/@fortawesome/fontawesome-free/css/brands.css";

import "flag-icon-css/css/flag-icons.min.css";
