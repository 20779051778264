$(document).ready(function () {
    // JQUERY CONNECTIONS START
    // jQuery("#_digiparent1").connections({
    //     to: "#_policy_making",
    //     css: { borderColor: "#165a72", borderWidth: "1px" },
    // });
    // jQuery("#_digiparent2").connections({
    //     to: "#_managing",
    //     css: { borderColor: "#05a3de", borderWidth: "1px" },
    // });
    // jQuery("#_digiparent3").connections({
    //     to: "#_being_creative",
    //     css: { borderColor: "#19b4a7", borderWidth: "1px" },
    // });
    // jQuery("#_public_institutions_sub_topic").connections({
    //     to: "#_public_institutions_topic",
    //     css: { borderColor: "#2188ff", zIndex: 1 },
    // });
    // jQuery("#_businesses_sub_topic").connections({
    //     to: "#_businesses_topic",
    //     css: { borderColor: "rgb(249 115 22)" },
    // });
    // jQuery("#_non_profits_sub_topic").connections({
    //     to: "#_non_profits_topic",
    //     css: { borderColor: "#04ba71" },
    // });
    // jQuery("#_cross_cutting_sub_topic").connections({
    //     to: "#_cross_cutting_topic",
    //     css: { borderColor: "#EC4899" },
    // });
    // var connections = $("connection");
    // setInterval(function () {
    //     connections.connections("update");
    // }, 100);

    // JQUERY CONNECTIONS END

    // My toggle
    // myToggle();
    myToggleById();
    myToggleBis();

    // sly
    $("#cycleitems").sly();

    jumpToFocus();
    jumpToClients();
    jumpToTop();
});
// function myToggle() {
//     // $(".toggle-trigger").mouseover(function () {
//     $(".toggle-trigger").click(function () {
//         // $(this).closest(".toggle-block").find(".toogle-icon").toggleClass('active');
//         var i = $(".toggle-able:visible").length;
//         $(this)
//             .closest(".toggle-block")
//             .find(".toggle-able")
//             .stop()
//             .slideToggle();
//         $(this)
//             .closest(".toggle-block")
//             .find(".toggle-trigger")
//             .find(".toggle-icon")
//             .toggle();
//         $(this)
//             .closest(".toggle-block")
//             .find(".toggle-trigger")
//             .find(".toggle-icon-activated")
//             .toggle();
//         if (i > 0) {
//             $(this)
//                 .closest(".toggle-block")
//                 .siblings()
//                 .find(".toggle-able")
//                 .stop()
//                 // .hide();
//                 .slideUp();
//             $(this)
//                 .closest(".toggle-block")
//                 .siblings()
//                 .find(".toggle-trigger")
//                 .removeClass("activated");
//         }
//     });
// }

function myToggleById() {
    // $(".toggle-trigger").mouseover(function () {
    $(".toggle-trigger").click(function () {
        // $(this).closest(".toggle-block").find(".toogle-icon").toggleClass('active');
        var k = $(".toggle-able:visible").length;
        // console.log(k);
        $("#" + $(this).attr("id") + "_details")
            .stop()
            .slideToggle();

        // if (k < 1) {
        //     $(this).closest(".toggle-parent").removeClass("pb-72");
        //     $(this).closest(".toggle-parent").addClass("pb-12");
        // }
        // if ($(this).closest(".toggle-parent").hasClass("pb-12")) {
        //     $(this).closest(".toggle-parent").removeClass("pb-12");
        //     $(this).closest(".toggle-parent").addClass("pb-72");
        // }

        if ($(this).hasClass("outline-my")) {
            $(this).removeClass("outline-my");
        } else {
            $(this).addClass("outline-my");
        }

        $(this)
            .closest(".toggle-block")
            .find(".toggle-trigger")
            .find(".toggle-icon")
            .toggle();
        $(this)
            .closest(".toggle-block")
            .find(".toggle-trigger")
            .find(".toggle-icon-activated")
            .toggle();

        $("html, body").animate(
            {
                scrollTop: $("#_flowchart_details").offset().top - 200,
            },
            2000
        );

        if (k > 0) {
            $("#" + $(this).attr("id") + "_details")
                .siblings()
                .stop()
                .slideUp();
            $(this)
                .closest(".toggle-block")
                .siblings()
                .find(".toggle-trigger")
                .removeClass("mt-20");
            $(this)
                .closest(".toggle-block")
                .siblings()
                .find(".toggle-trigger")
                .find(".toggle-icon")
                .show();
            $(this)
                .closest(".toggle-block")
                .siblings()
                .find(".toggle-trigger")
                .find(".toggle-icon-activated:visible")
                .hide();
            $(this)
                .closest(".toggle-block")
                .siblings()
                .find(".toggle-trigger")
                .removeClass("outline-my");
            // $(this).siblings().removeClass("activated");
        }
    });
}
function myToggleBis() {
    // $(".toggle-trigger").mouseover(function () {
    $(".toggle-trigger-bis").click(function () {
        // $(this).closest(".toggle-block").find(".toogle-icon").toggleClass('active');
        var j = $(".toggle-bis-able:visible").length;
        // console.log($(this).attr("id"));
        // console.log(j);
        $("#" + $(this).attr("id") + "_details")
            .stop()
            .slideToggle();
        $("#" + $(this).attr("id") + "_toggle_icon").toggle();
        $("#" + $(this).attr("id") + "_toggle_icon_activated").toggle();
        $("html, body").animate(
            {
                scrollTop: $("#_clients_services_menutop").offset().top,
            },
            2000
        );
        if (j > 0) {
            $("#" + $(this).attr("id") + "_details")
                .siblings()
                .stop()
                .slideUp();
            $(this)
                // .closest(".toggle-block")
                .siblings()
                // .find(".toggle-trigger")
                .find(".toggle-icon")
                .show();
            $(this)
                //  .closest(".toggle-block")
                .siblings()
                //  .find(".toggle-trigger")
                .find(".toggle-icon-activated:visible")
                .hide();
        }
    });
}

function jumpToFocus() {
    $("#_focus_menu").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_business_focus_areas").offset().top,
            },
            200
        );
    });
}

function jumpToClients() {
    $("#_clients_services_menu").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_clients_services").offset().top,
            },
            200
        );
    });
}

function jumpToTop() {
    $(".jump-top").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#_menu_second").offset().top,
            },
            200
        );
    });
}
